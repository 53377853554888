body, html {
  overflow-x: hidden; /* Prevent horizontal scrolling */
}

.home-container {
  display: flex;
  flex-direction: column;
  margin-top: 102px;
  position: fixed;
  left: 0;
  right: 0;
  height: calc(100vh - 102px); 
  overflow-y: auto; /* Allow vertical scrolling */
  width: 100%; 
}

.bible-contents-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-image: linear-gradient(to right, #878c8e, #424b52);
  width: 100%;
}

.screen-saver {
  flex: 1;
  width: 100%;
  height: auto;
}

.button-container {
  flex: 1;
  display: flex;
  justify-content: center;
}

.custom-button {
  width: 260px;
  height: 150px;
  background-image: linear-gradient(135deg, #fbfbfb, #a3a4a4, #ffffff);
  border: none;
  cursor: pointer;
}

.home-container .bible-contents-container .button-container .custom-button {
  margin-right: 4px;
  color: #3b3b3b;
  font-size: 18px !important;
  box-shadow: 0 2px 4px rgba(255, 255, 255, 0.2);
  transition: background-color 0.3s ease, box-shadow 0.3s ease, transform 0.3s ease;
  border-radius: 15px;
  font-weight: 300;
}

.home-container .bible-contents-container .button-container .custom-button:last-child {
  margin-right: 0;
}

.custom-button:hover,
.custom-button:focus {
  background-image: linear-gradient(135deg, #37ecba, #72afd3);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transform: translateY(0px);
}

.custom-button:active {
  transform: translateY(1px);
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.2);
}

.icon-size {
  font-size: 40px;
}

.text-size {
  font-size: 18px;
}

/* Tab View */
@media (max-width: 1024px) {
  .bible-contents-container {
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: auto;
  }

  .screen-saver {
    width: 100%;
    height: auto;
  }

  .button-container {
    flex-direction: row;
    width: 100%;
  }

  .custom-button {
    width: 100%;
    height: 100px;
    margin-bottom: 10px;
  }

  .custom-button:last-child {
    margin-bottom: 0;
  }

  .icon-size {
    font-size: 30px;
  }

  .text-size {
    font-size: 16px;
  }
}

/* Mobile View */
@media (max-width: 768px) {
  .home-container {
    /* position: relative; */
    margin-top: 95px;
    padding: 0;
  }


  .bible-contents-container {
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: auto;
    padding: 0;
  }

  .screen-saver {
    width: 100%;
    height: auto;
  }

  .button-container {
    flex-direction: row;
    width: 100%;
    justify-content: space-around;
  }

  .custom-button {
    width: 30%; /* Adjust width for mobile */
    height: auto;
    margin-bottom: 10px;
  }

  .custom-button:last-child {
    margin-bottom: 0;
  }

  .icon-size {
    font-size: 24px; /* Smaller icons for mobile */
  }

  .text-size {
    font-size: 12px;
  }
}
