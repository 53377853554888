/* Support.css */
.support-container {
    margin: 0 auto;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin-top: 80px;
  }
  
  .support-container h2 {
    text-align: center;
    font-size: 2rem;
    color: #333;
    margin-bottom: 20px;
  }
  
  .support-container p {
    font-size: 1.2rem;
    color: #555;
    line-height: 1.5;
  }
  
  .support-email {
    font-weight: bold;
    color: #007bff;
    text-decoration: none;
  }
  
  .support-email:hover {
    text-decoration: underline;
  }
  
  .faq-section {
    margin-top: 30px;
  }
  
  .faq-section h3 {
    font-size: 1.5rem;
    color: #333;
    margin-bottom: 15px;
  }
  
  .faq-section ul {
    list-style-type: none;
    padding: 0;
  }
  
  .faq-section li {
    margin-bottom: 10px;
  }
  
  .faq-section li strong {
    font-size: 1rem;
    color: #555;
  }
  
  .faq-section li p {
    margin: 5px 0 0;
    font-size: 0.95rem;
    color: #777;
  }
  
  .additional-resources {
    margin-top: 20px;
  }
  
  .additional-resources h3 {
    font-size: 1.5rem;
    color: #333;
  }
  
  .additional-resources p {
    font-size: 1rem;
    color: #555;
  }
  
  .additional-resources a {
    color: #007bff;
    text-decoration: none;
    transition: color 0.3s;
  }
  
  .additional-resources a:hover {
    color: #0056b3;
  }
  