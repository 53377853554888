.article-details-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background-color: #fff; 
  color: #333; 
  box-sizing: border-box;
  width: 99%;
  padding: 10px;
  position: fixed;
  top: 70px; 
  left: 0;
  height: calc(100vh - 70px); 
  overflow: hidden; 
}

.article-details {
  width: 100%;
  background-color: #f7f7f7; 
  padding: 25px;
  flex-grow: 1; 
  overflow-y: auto; 
}

.article-details h2.highlight {
  width: 100%;
  font-size: 25px;
  color: #050505; 
  padding: 1px 0;
  border-bottom: 1px solid #2c3e50; 
}

.article-details .text p {
  font-size: 16px;
  line-height: 1.6;
  color: #333; 
  text-align: justify;
  word-wrap: break-word;
}

.article-details .text p.bible-verse {
  font-size: 16px;
  margin-left: 0;
  font-style: normal;
  line-height: 1.6;
  color: blue;
  text-align: start;
}

.article-details .text p.styled-paragraph {
  font-size: 16px;
  color: blue; 
  text-align: left;
  max-width: 99%; 
}

.article-details .text p.bold-text {
  font-size: 16px;
  color: #333;
  text-align: justify;
  word-wrap: break-word;
}
