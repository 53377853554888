.spinner-container-chapters {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 90vh;
}

.loading-spinner-chapters {
    border: 8px solid #f3f3f3;
    border-top: 8px solid #ffcc00;
    border-radius: 50%;
    width: 80px;
    height: 80px;
    animation: spin 1.5s linear infinite;
} 

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.spinner-container-chapters p {
    color: #ffffff;
    font-size: 1.5rem;
    margin-top: 10px;
    font-weight: bold;
}

.old-testament-chapters-container {
    margin: 0;
    padding: 30px;
    background: linear-gradient(135deg, #ffffff, #818b94);
    min-height: 90vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: Arial, sans-serif;
    color: rgb(255, 255, 255);
    margin-top: 80px;
    position: fixed;
}

.old-testament-chapters-container h2 {
    font-size: 2rem;
    color: #000000;
    margin-bottom: 1rem;
    text-align: center;
    letter-spacing: 1px;
    text-transform: uppercase;
    animation: fadeIn 1.5s ease-in-out;
    font-weight: 700;
}

.old-testament-chapters-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 0px;
}

.old-testament-chapter-link {
    display: inline-block;
    width: 180px;
    padding: 2px 2px;
    background-color: #424b52;
    border: 1px solid #ffcc00;
    color: #ffffff;
    font-size: 1rem;
    text-align: center;
    border-radius: 10px;
    text-transform: uppercase;
    cursor: pointer;
    transition: all 0.4s ease-in-out;
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.2);
    position: relative;
    overflow: hidden;
    z-index: 1;
    font-weight: 500;
}

.old-testament-chapter-link:hover {
    background-color: #ffcc00;
    color: #333;
    transform: translateY(-5px);
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.4);
}

.old-testament-chapter-link:focus {
    outline: none;
    background-color: #ffcc00;
    color: #333;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.4);
}

.old-testament-chapter-link:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 300%;
    height: 300%;
    background: rgba(255, 255, 255, 0.1);
    transform: translate(-50%, -50%) rotate(45deg);
    transition: all 0.75s ease;
    z-index: -1;
}

.old-testament-chapter-link:hover:before {
    width: 0;
    height: 0;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
        transform: translateY(-20px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

@media (max-width: 600px) {
    .old-testament-chapters-container h2 {
        font-size: 1.8rem;
    }

    .old-testament-chapter-link {
        width: 180px;
        font-size: 1rem;
    }
}
 