.contact-container {
    max-width: 190%;
    margin-top: -20px;
    margin-left: auto;
    margin-right: auto;
    padding: 20px;
    border: 2px solid #007bff;
    border-radius: 10px;
    background-color: #f0f0f0;
  }
  
  .contact-container h2 {
    font-size: 24px;
    margin-bottom: 20px;
    text-align: center;
  }
  
  .form-group {
    margin-bottom: 20px;
  }
  
  .form-group label {
    display: block;
    font-weight: bold;
  }
  
  .form-group input[type='text'],
  .form-group input[type='email'],
  .form-group textarea {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .form-group textarea {
    height: 150px;
  }
  
  button[type='submit'] {
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    display: block;
    margin: 0 auto;
  }
  
  button[type='submit']:hover {
    background-color: #0056b3;
  }
  