.old-testament-container {
  margin: 20px auto;
  padding: 20px;
  max-width: 900px;
  background-color: #f8f9fa;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  margin-top: 80px;
}

.old-testament-heading {
  font-size: 26px;
  color: #343a40;
  padding: 15px 0;
  text-align: center;
  font-family: 'Arial', sans-serif;
  font-weight: bold;
  background: linear-gradient(45deg, #f2f2f2, #d9d9d9);
  border-radius: 10px 10px 0 0;
  box-shadow: inset 0 -2px 5px rgba(0, 0, 0, 0.05);
}

table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
  background-color: #ffffff;
}

th, td {
  padding: 15px;
  font-size: 18px;
  border-bottom: 1px solid #ddd;
  vertical-align: middle; 
}

th {
  background-color: #007bff;
  color: white;
  font-weight: bold;
  font-size: 20px;
}

.index-column {
  width: 10%; 
  text-align: center; 
}

.book-name-column {
  width: 90%; 
  text-align: left; 
}

td {
  color: #333;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
}

td:hover {
  background-color: #e9ecef;
  color: #007bff;
}

.old-testament-header-cell {
  background: #424b52;
  color: white;
}

.old-testament-book-row:hover {
  background-color: #f1f3f5;
}

.old-testament-book-clicked {
  color: #dc3545;
  font-weight: bold;
}

.old-testament-chapter-link {
  display: inline-block;
  font-size: 16px;
  padding: 5px 10px;
  margin: 5px;
  border-radius: 5px;
  color: #007bff;
  background-color: #f8f9fa;
  text-decoration: none;
  transition: background-color 0.3s ease, color 0.3s ease;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.old-testament-chapter-link:hover {
  background-color: #007bff;
  color: white;
}

.old-testament-loading {
  font-size: 16px;
  color: #6c757d;
  padding: 20px;
  text-align: center;
}

/* Tablet styles */
@media only screen and (max-width: 768px) {
  .old-testament-container {
    padding: 15px;
  }

  th, td {
    padding: 12px;
    font-size: 16px;
  }

  .old-testament-heading {
    font-size: 22px;
  }

  .index-column {
    width: 15%;
  }

  .book-name-column {
    width: 85%;
  }

  .old-testament-chapter-link {
    font-size: 14px;
    padding: 4px 8px;
  }
}

/* Mobile styles */
@media only screen and (max-width: 480px) {
  .old-testament-container {
    padding: 10px;
  }

  th, td {
    padding: 10px;
    font-size: 14px;
  }

  .old-testament-heading {
    font-size: 20px;
  }

  .index-column {
    width: 20%;
  }

  .book-name-column {
    width: 80%;
  }

  .old-testament-chapter-link {
    font-size: 12px;
    padding: 3px 6px;
  }
}
 