/* Songs.css */

.admin-panel {
  display: flex;
  width: 100%;
  height: 100%;
}

.left-section, .right-section {
  padding: 20px;
  box-sizing: border-box;
  overflow-y: auto;
}

.left-section {
  width: 35%;
  border-right: 1px solid #ddd;
}

.right-section {
  width: 65%;
}

h2, h3 {
  margin: 0 0 10px;
  color: #333;
}

h3 {
  margin-top: 20px;
  cursor: pointer;
}

form {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

form input, form select, form textarea {
  width: 100%;
  padding: 10px;
  margin: 5px 0;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
}

form button {
  background-color: #ffcc00;
  color: #333;
  border: none;
  padding: 10px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s ease;
}

form button:hover {
  background-color: #e6b800;
}

.message {
  color: green;
  margin-bottom: 20px;
}

ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

ul li {
  background-color: #f9f9f9;
  margin: 10px 0;
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
}

.details img {
  max-width: 50px;
  max-height: 50px;
  margin-right: 15px;
  border-radius: 8px;
  object-fit: cover;
}

.details {
  flex: 1;
}
