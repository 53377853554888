.form-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.form-wrapper {
    width: 80%;
    max-width: 600px;
}

.form { 
    display: flex;
    flex-direction: column;
}

.form-input,
.form-textarea {
    margin-bottom: 15px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.form-input {
    height: 40px;
}

.form-textarea {
    min-height: 150px;
}

.form-file {
    margin-bottom: 15px;
}

.form-button {
    padding: 12px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.form-button:hover {
    background-color: #0056b3;
}

.success-message {
    margin-top: 20px;
    color: green;
    font-weight: bold;
}
