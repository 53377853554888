.footer {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 10px;
  padding: 20px;
  background-color: #424b52;
  color: #fff;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.3);
  border-top: 3px solid #d4af37;
}

.footer-section {
  display: flex;
  flex-direction: column;
}

.footer-section h4 {
  margin-bottom: 0px;
  font-size: 18px;
  font-weight: bold;
  color: #d4af37;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.footer-section p {
  font-size: 14px;
  color: #b0b0b0;
  line-height: 1.1;
}

.footer-book-link,
.footer-subcategory-link {
  color: #000; 
  text-decoration: none;
  font-size: 16px;
  cursor: pointer;
  transition: color 0.3s ease, transform 0.3s ease;
}

.footer-book-link:hover,
.footer-subcategory-link:hover {
  color: #d4af37;
  text-decoration: underline;
  transform: translateX(5px);
}

.footer-section ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
  max-height: 250px;
  overflow-y: auto;
  scrollbar-width: thin;
}

.footer-section li {
  margin-bottom: 1px;
}

.profile-pic {
  width: 100px;
  height: 120px;
  border-radius: 50%;
  margin-bottom: 10px;
}

.footer-bar {
  background-color: #333;
  color: #fff;
  text-align: center;
  padding: 10px 0;
  border-top: 1px solid #555;
  font-size: 14px;
}

/* Custom scrollbar for footer section */
.footer-section ul::-webkit-scrollbar {
  width: 6px;
}

.footer-section ul::-webkit-scrollbar-thumb {
  background-color: #d4af37;
  border-radius: 3px;
}

/* Mobile styling */
@media (max-width: 768px) {
  .footer {
    grid-template-columns: 1fr;
    padding: 15px;
  }

  .footer-section h4 {
    font-size: 16px;
    margin-bottom: 5px;
  }

  .footer-section ul {
    max-height: 200px;
  }

  .footer-section li {
    font-size: 14px;
    margin-bottom: 5px;
  }

  .profile-pic {
    width: 80px;
    height: 100px;
    margin-bottom: 5px;
  }

  .footer-bar {
    font-size: 12px;
    padding: 8px 0;
  }
}
