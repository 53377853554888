.daily-verse-container {
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
}

.daily-verse {
  border-radius: 8px;
  padding: 1px;
  background-image: linear-gradient(to right, #60686e, #acacac, #60686e);
  position: relative;
  display: flex;
  flex-direction: column;
  color: #000000;
  width: 100%; 
  max-width: 100%;
  min-height: 200px;
}

.custom-heading {
  font-size: 0.7rem;
  color: #333; 
  text-align: start; 
  font-weight: bold; 
  margin: 5px 0; 
  padding: 1px; 
}

.book {
  font-weight: 600;
  font-size: 0.5em;
}

.verse-info {
  color: #171b39;
  font-size: 17px;
  margin-bottom: 0; 
}

.verse-text-container {
  height: 150px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.verse-text {
  margin-top: 0;
  margin-bottom: 20px;
  color: #000000;
  font-size: 19px;
  font-weight: 400;
  text-align: center;
}

.share-icons {
  position: absolute;
  top: 10px;
  right: 10px;
  display: flex;
  gap: 8px;
}

.share-icons button {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 24px;
}

.share-icons button .fa-heart {
  color: red;
}

.share-options {
  position: absolute;
  top: 50px;
  right: 0;
  display: flex;
  gap: 8px;
  background-color: rgba(22, 22, 22, 0.7);
  border-radius: 10px;
  padding: 10px;
  z-index: 9999;
}

.share-options button {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 24px;
}

.book-name {
  font-weight: bold;
}

.daily-verse h3 {
  font-size: 0.8em;
  font-weight: 200;
  margin-bottom: 10px;
  color: #ffffff;
}

