/* Main container for the search form and result section */
.searchword-container {
    display: flex;
    justify-content: space-between;
    position: fixed;
    top: 90px;
    left: 0;
    right: 0;
    height: calc(100vh - 90px);
    overflow-y: auto;
    width: 100%;
    background-color: #f7f7f7;
}

.searchword-form-container {
    padding: 30px;
    background: linear-gradient(to bottom, #ffffff, #ffffff);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 40%;
    min-width: 250px;
    border-radius: 8px;
}

.searchword-result-container {
    padding: 20px;
    background: #ffffff;
    border-radius: 8px;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
    overflow-y: auto;
    max-height: calc(100vh - 150px);
    margin-top: 20px;
    font-size: 16px;
    color: #333333;
    line-height: 1.7;
    width: 60%;
}

/* Loader Styles */
.loading-indicator {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    color: #000;
}

.loading-indicator:before {
    content: '';
    width: 40px;
    height: 40px;
    border: 5px solid #ccc;
    border-top-color: #007bff;
    border-radius: 50%;
    animation: spin 1s linear infinite;
    margin-right: 10px;
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.searchword-form-row {
    margin-bottom: 20px;
    display: flex;
    align-items: center;
}

.searchword-form-row label {
    margin-right: 10px;
    flex: 0 0 40%;
}

.searchword-form-input {
    flex: 1;
    padding: 10px;
    border-radius: 4px;
    border: 1px solid #ccc;
}

.searchword-form-input input,
.searchword-form-input select {
    width: 100%;
    border: none;
    outline: none;
    padding: 8px;
}

.searchword-submit-reset-buttons-column {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.searchword-submit-button,
.searchword-reset-button {
    padding: 12px 20px !important;
    width: 60%;
    border-radius: 4px;
    border: none;
    background-color: #28a745;
    color: #ffffff;
    cursor: pointer;
    font-size: 14px !important;
    transition: background-color 0.3s ease, transform 0.2s ease;
    text-align: center;
}

.searchword-submit-button:hover,
.searchword-reset-button:hover {
    background-color: #218838;
    transform: scale(1.05);
}

.searchword-language-selection {
    display: flex;
    gap: 10px;
}

.searchword-language-selection label {
    display: flex;
    align-items: center;
}

.searchword-language-selection input {
    margin-right: 5px;
}

.verse-combined-column {
    margin-bottom: 20px;
    padding: 15px;
    background-color: #f9f9f9;
    border-left: 4px solid #007bff;
    border-radius: 4px;
}

.verse-line {
    margin-bottom: 8px;
    font-family: 'Arial', sans-serif;
    font-weight: 400;
    padding-left: 5px;
}

.verse-line:last-child {
    margin-bottom: 0;
}

.verse-line:hover {
    background-color: #f1f9ff;
    transition: background-color 0.3s ease-in-out;
}

.highlight {
    color: red;
    font-weight: bold;
}

/* Media queries for responsiveness */
@media (max-width: 767px) {
    .searchword-container {
        flex-direction: column;
    }

    .searchword-form-container,
    .searchword-result-container {
        width: 100%;
        margin-bottom: 20px;
    }

    .verse-combined-column {
        margin-bottom: 15px;
        padding: 10px;
    }
}

/* Additional enhancements for mobile responsiveness */
@media (max-width: 767px) {
    .searchword-form-row {
        flex-direction: column;
        align-items: flex-start;
    }

    .searchword-form-row label {
        margin-bottom: 5px;
        flex: 0;
    }

    .searchword-submit-reset-buttons-column {
        flex-direction: row;
        justify-content: space-between;
    }

    .searchword-submit-button,
    .searchword-reset-button {
        width: 48%;
    }
}
