.news-content-container {
    width: 100%; 
    margin-top: 70px; 
    padding: 20px; 
    background-color: #f8f9fa; 
    border: 1px solid #dee2e6; 
    border-radius: 5px; 
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); 
  }
   
  .news-title, .news-subtitle {
    color: #333; 
  }
  
  .news-title {
    font-size: 24px; 
    font-weight: bold;
    margin-top: 0;
    margin-bottom: 0px; 
  }
  
  .news-subtitle {
    font-size: 15px; 
    font-weight: normal; 
    color: #555; 
    margin-top: 0;
    margin-bottom: 10px;
  }
  
  .news-image {
    width: 700px; 
    height: 300px; 
    object-fit: cover; 
    border-radius: 3px; 
    margin-bottom: 20px; 
  }
  
  .news-text {
    font-size: 16px; 
    line-height: 1.6; 
    color: #666; 
    text-align: justify; 
  }
  
  .like-comment-share-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
  }
  
  .like-comment-share-container button {
    background-color: #007bff;
    color: #fff;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .like-comment-share-container button:hover {
    background-color: #0056b3;
  }
  
  .comment-count {
    color: #333;
    font-weight: bold;
  }
  
  .share-count {
    color: #333;
    font-weight: bold;
  }
  
  .like-comment-share-container span {
    margin-left: 10px;
  }

  .comment-form {
    display: flex;
    margin-top: 20px;
  }
  
  .comment-input {
    flex-grow: 1;
    padding: 10px;
    border: 1px solid #dee2e6;
    border-radius: 5px 0 0 5px;
    font-size: 16px;
    color: #333;
  }
  
  .comment-submit {
    padding: 10px 20px;
    background-color: #28a745;
    color: white;
    border: none;
    border-radius: 0 5px 5px 0;
    cursor: pointer;
  }
  
  .comment-submit:hover {
    background-color: #218838;
  }