/* Dashboard.css */

.dashboard {
    display: flex;
    height: 100vh;
    font-family: Arial, sans-serif;
    background-color: #f1f1f1;
  }
  
  .sidebar {
    width: 250px;
    background-color: #23282d;
    color: #fff;
    padding: 20px;
    box-sizing: border-box;
  }
  
  .sidebar ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .sidebar ul li {
    margin-bottom: 10px;
  }
  
  .nav-link {
    display: block;
    padding: 10px 15px;
    color: #c2c7d0;
    text-decoration: none;
    font-size: 16px;
    border-radius: 4px;
    transition: background-color 0.3s ease, color 0.3s ease;
  }
  
  .nav-link:hover {
    background-color: #343a40;
  }
  
  .nav-link.active {
    color: #fff;
    background-color: #0073aa;
  }
  
  .main-content {
    flex-grow: 1;
    padding: 20px 40px;
    background-color: #ffffff;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    overflow-y: auto;
  }
  
  .dashboard-header {
    font-size: 2rem;
    color: #333;
    margin-bottom: 20px;
    font-weight: 700;
    text-transform: uppercase;
    text-align: left;
  }
  