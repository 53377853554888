.articles-container {
    padding: 10px;
    background-color: #f9f9f9;
    margin-top: 95px;
    border-radius: 10px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    position: fixed; 

    left: 0; 
    right: 0; 
    height: calc(100vh - 95px); 
    overflow-y: auto; 
    max-width: 99%; 
    margin-left: auto;
    margin-right: auto;
}

.articles-container h2 {
    font-size: 26px;
    font-weight: 500;
    color: #2c3e50;
    text-align: center;
}

.articles-container ul {
    list-style-type: none;
    padding: 0;
}

.articles-container li {
    background-color: #ffffff;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    transition: background-color 0.3s ease, box-shadow 0.3s ease;
    cursor: pointer;
    margin-bottom: 10px;
    padding: 15px;
    border-radius: 8px;
}

.articles-container li:hover {
    background-color: #f0f0f0;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
}

.articles-container h3 {
    font-size: 18px;
    font-weight: 400;
    color: #34495e;
}

.articles-container p {
    font-size: 16px;
    color: #333;
    text-align: center;
}

.loading-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80vh; 
    flex-direction: column;
}

.loading-spinner {
    border: 8px solid #f3f3f3;
    border-top: 8px solid #ffcc00;
    border-radius: 50%;
    width: 80px;
    height: 80px;
    animation: spin 1.5s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

/* Loading text */
.loading-container p {
    font-size: 18px;
    color: #34495e;
    margin-top: 15px;
    font-weight: 600;
    letter-spacing: 0.5px;
    text-align: center;
}

/* Responsive Styles */
@media (max-width: 1024px) {
    .articles-container {
        padding: 20px;
        max-width: 95%;
    }

    .articles-container h2 {
        font-size: 24px;
    }

    .articles-container li {
        font-size: 17px;
    }
}

@media (max-width: 768px) {
    .articles-container {
        padding: 15px;
        max-width: 95%;
    }

    .articles-container h2 {
        font-size: 22px;
    }

    .articles-container li {
        font-size: 16px;
    }
}

@media (max-width: 480px) {
    .articles-container {
        padding: 10px;
        max-width: 98%;
    }

    .articles-container h2 {
        font-size: 20px;
    }

    .articles-container li {
        font-size: 15px;
    }
}
