.news-container {
  max-width: 100%;
  margin: 0 auto;
  margin-top: 10px;
}

.news-items {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.news-item {
  width: calc(33.33% - 30px);
  margin-bottom: 20px;
  margin-right: 10px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(66, 75, 82, 0.1);
  padding: 10px;
  background-color: #ffffff;
}

.news-item:nth-child(3n) {
  margin-right: 0;
}

.news-title {
  font-size: 13px;
  margin-bottom: 10px;
  color: #333333;
}

.news-subtitle {
  font-size: 13px;
  margin-bottom: 10px;
  color: #555555;
}

.news-content {
  font-size: 14px;
  line-height: 1.5;
  color: #777777;
}

.news-image {
  width: 100%;
  height: 200px;
  object-fit: cover;
  border-radius: 8px;
}

.news-heading {
  text-align: center;
  font-size: 36px;
  margin-bottom: 40px;
  color: #424b52;
}

.news-header-container {
  border-radius: 8px;
  padding: 20px;
  background-image: linear-gradient(#ffffff, #ffffff);
  transition: transform 0.3s ease, box-shadow 0.3s ease, background-image 0.3s ease;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
  margin-bottom: 0;
  overflow: hidden;
  position: relative;
}

.news-header-container:hover {
  transform: scale(1.02) translateY(-1px);
  background-image: linear-gradient(to left, #2a434d, #2a434d);
}

.news-header {
  text-align: start;
  font-size: 36px;
  color: #ffffff;
  margin: 0;
  position: relative;
  z-index: 1;
  transition: color 0.3s ease;
  background-color: #424b52;
  padding: 20px;
}

.news-header:hover {
  color: #ffcc00;
}

.news-item a {
  text-decoration: none;
  color: inherit;
  transition: text-shadow 0.3s ease, color 0.3s ease;
}

.news-item a:hover, .news-item a:focus {
  text-shadow: 1px 1px 8px rgb(255, 217, 0);
  color: #007bff;
}
