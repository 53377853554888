.loading-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f9f9f9;
    flex-direction: column;
}

.loading-text {
    margin-top: 10px;
    font-size: 18px;
    font-weight: 500;
    color: #333;
}

.loading-spinner {
    border: 8px solid #f3f3f3;
    border-top: 8px solid #ffcc00;
    border-radius: 50%;
    width: 80px;
    height: 80px;
    animation: spin 1.5s linear infinite;
}

.container { 
    padding: 10px;
    background-color: #d3d3d3;
    border-radius: 12px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    position: fixed; 
    top: 110px; 
    left: 0; 
    right: 0; 
    overflow-y: auto; 
    max-width: 100%; 
    margin-left: auto;
    margin-right: auto;
    transition: all 0.3s ease-in-out;
}

.category-container {
    padding: 15px;
    background-color: #f8f8f8;
    border-radius: 10px;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    cursor: pointer;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    border-left: 5px solid #ffcc00; 
}

.category-container:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 16px rgba(0, 0, 0, 0.15);
    background-color: #f0f0f0; 
}

.category-text {
    font-size: 22px;
    font-weight: 700;
    color: #2c3e50;
}

.description-text {
    font-size: 17px;
    color: #7f8c8d;
}

.error-text {
    color: #e74c3c;
    text-align: center;
    font-weight: 600;
}

@media (max-width: 1024px) {
    .container {
        padding: 20px;
        max-width: 95%;
    }

    .category-text {
        font-size: 20px;
    }

    .description-text {
        font-size: 15px;
    }
}

@media (max-width: 768px) {
    .container {
        padding: 15px;
        max-width: 95%;
    }

    .category-text {
        font-size: 18px;
    }

    .description-text {
        font-size: 14px;
    }
}

@media (max-width: 480px) {
    .container {
        padding: 10px;
        max-width: 98%;
    }

    .category-text {
        font-size: 16px;
    }

    .description-text {
        font-size: 12px;
    }
}

/* Spinner Animation */
@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}
