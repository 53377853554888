.screensaver-container {
  background-color: rgb(254, 254, 254);
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
  border: 2px solid #ccc;
  border-radius: 10px;
  overflow: hidden;
  position: relative;
  width: 100%; 
  height: 90%;
  max-width: 700px;
  margin: 0 auto;
}

.screensaver-slideshow {
  display: flex;
  width: 100%;
  height: 100%; 
  transition: transform 1s ease;
}

.slide {
  width: 100%;
  height: 100%; 
  position: relative;
  flex-shrink: 0; 
}

.slide-image {
  width: 100%;
  height: 100%;
  object-fit: cover; 
}

.centered-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: clamp(1rem, 2vw + 0.5rem, 1.4rem); /* Adjusted font size */
  color: white;
  text-align: center;
  padding: 10px 20px;
  background-color: rgba(0, 0, 0, 0.6);
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

.bottom-text {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  font-size: clamp(0.5rem, 1.5vw + 0.2rem, 0.6rem); /* Adjusted font size */
  color: white;
  background-color: rgba(0, 0, 0, 0.6);
  padding: 5px 10px;
  border-radius: 5px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
}

.prev-btn,
.next-btn {
  position: absolute;
  background: rgba(0, 0, 0, 0.5);
  border: none;
  color: white;
  font-size: 18px;
  padding: 10px;
  cursor: pointer;
  border-radius: 50%;
  z-index: 1;
}

.prev-btn:hover,
.next-btn:hover {
  background: rgba(0, 0, 0, 0.8);
}

.prev-btn {
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
}

.next-btn {
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
}

@media screen and (max-width: 1024px) {
  .screensaver-container {
    height: auto; 
  }

  .prev-btn,
  .next-btn {
    font-size: 14px;
    padding: 8px;
  }
}

@media screen and (max-width: 768px) {
  .screensaver-container {
    height: auto; 
  }

  .prev-btn,
  .next-btn {
    font-size: 12px;
    padding: 6px;
  }
}

.slide-dots {
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
}

.dot {
  width: 10px;
  height: 10px;
  margin: 0 5px;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.5);
  cursor: pointer;
  transition: background-color 0.4s ease, transform 0.4s ease;
}

.dot.active {
  background-color: rgba(255, 255, 255, 0.8);
  transform: scale(1.5);
}
