/* Language Selector Container */
.language-selector-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background: linear-gradient(135deg, #424b52, #2d343a);
    color: #fff;
    font-family: Arial, sans-serif;
}

/* Heading for Language Selector */
.language-selector-heading {
    font-size: 2rem;
    color: #ffcc00;
    margin-bottom: 2rem;
    text-align: center;
    letter-spacing: 1.5px;
    text-transform: uppercase;
    animation: fadeIn 1.5s ease-in-out;
}

/* Language Options (Column Layout) */
.language-options {
    display: flex;
    flex-direction: column;  /* This ensures the buttons are displayed in a column */
    align-items: center;
}

/* Language Button Styling */
.language-button {
    display: inline-block;
    width: 250px;
    padding: 15px 20px;
    margin: 10px 0; 
    background-color: #424b52;
    border: 2px solid #ffcc00;
    color: #ffffff;
    font-size: 1.2rem;
    text-align: center;
    border-radius: 50px;
    text-transform: uppercase;
    cursor: pointer;
    transition: all 0.4s ease-in-out;
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.2);
    position: relative;
    overflow: hidden;
    z-index: 1;
    font-weight: bold;
}

.language-button:hover {
    background-color: #ffcc00;
    color: #333;
    transform: translateY(-5px);
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.4);
}

.language-button:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 300%;
    height: 300%;
    background: rgba(255, 255, 255, 0.1);
    transform: translate(-50%, -50%) rotate(45deg);
    transition: all 0.75s ease;
    z-index: -1;
}

.language-button:hover:before {
    width: 0;
    height: 0;
}

/* Button Focus State */
.language-button:focus {
    outline: none;
    background-color: #ffcc00;
    color: #333;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.4);
}

/* Animation for Heading */
@keyframes fadeIn {
    0% {
        opacity: 0;
        transform: translateY(-20px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

/* Mobile Styles */
@media (max-width: 600px) {
    .language-selector-heading {
        font-size: 1.5rem;
    }

    .language-button {
        width: 200px;
        font-size: 1rem;
    }
}
