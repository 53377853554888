.singer-and-album-list-container {
  display: flex;
  flex-direction: row;
  background-color: #414141; 
  position: fixed;
  width: 100%;
  margin-top: 90px;
}

.singer-and-album-list {
  width: 30%;
  padding: 20px;
  border-right: 1px solid #ccc;
  background-color: #424b52; 
  color: #fff;
  overflow-y: auto;
  height: 100vh;
}

.singer-name {
  font-size: 18px;
  cursor: pointer;
  padding: 10px;
  border-radius: 5px;
  transition: background-color 0.3s ease, transform 0.3s ease;
  margin-bottom: 5px;
}

.name-tamil {
  display: block;
  font-size: 20px;
  font-weight: bold;
}

.name-english {
  display: block;
  font-size: 16px;
  color: #aaa;
}

.singer-name:hover,
.singer-name.selected {
  background-color: #ffd000;
  color: #000;
  transform: scale(1.05);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

.singer-name:hover .name-english,
.singer-name.selected .name-english {
  color: #000;
}

.song-list {
  width: 70%;
  padding: 20px;
  background-color: #74747466; 
  color: #fff;
  overflow-y: auto;
  height: 100vh;
}

.search-box {
  width: calc(100% - 40px);
  padding: 10px;
  margin: 20px;
  border-radius: 5px;
  border: 1px solid #ccc;
  font-size: 16px;
  color: #000;
}

.song-list h3 {
  font-size: 24px;
  border-bottom: 2px solid #3a3a3a;
  padding-bottom: 10px;
  margin-bottom: 10px;
  color: #ffd000; 
}

.song-list ul {
  list-style: none;
  padding: 0;
}

.song-list li {
  margin: 5px 0;
  padding: 10px;
  border-radius: 5px;
  background-color: #45454529; 
  transition: background-color 0.3s ease, transform 0.3s ease;
  cursor: pointer;
}

.song-list li:hover,
.song-list li.highlight {
  background-color: #ffd000;
  color: #000;
  transform: scale(1.05);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

.instruction-container {
  text-align: start;
  margin-top: 20px;
  background-color: #2e2e2e4c;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.instruction-tamil {
  font-size: 20px;
  color: #ccc;
}

.instruction-english {
  font-size: 20px;
  color: #ccc;
}

.arrow {
  display: block;
  color: #ffd000;
  font-size: 44px;
  animation: arrow-animation 1.5s infinite;
}

@keyframes arrow-animation {
  0% { transform: translateX(0); }
  50% { transform: translateX(-10px); }
  100% { transform: translateX(0); }
}
