.bibleversefind-container {
    display: flex;
    justify-content: space-between;
    position: fixed; 
    top: 90px; 
    left: 0;
    right: 0;
    height: calc(100vh - 90px); 
    overflow-y: auto; 
    width: 100%;
}

.bibleversefind-form-container {
    padding: 30px;
    background: linear-gradient(to bottom, #ffffff, #ffffff);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 40%;
    
}

.bibleversefind-result-container {
    padding: 10px;
    background: linear-gradient(to bottom, #f1f1f1, #f3f3f3);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 60%;
    min-width: 250px;
}

.bibleversefind-form-row {
    margin-bottom: 20px;
    display: flex;
    align-items: center;
}

.bibleversefind-form-row label {
    margin-right: 10px;
    flex: 0 0 40%;
}

.bibleversefind-form-input {
    flex: 1;
    padding: 10px;
    border-radius: 4px;
    border: 1px solid #ccc;
}

.bibleversefind-form-input input,
.bibleversefind-form-input select {
    width: 100%;
    border: none;
    outline: none;
}

.bibleversefind-submit-reset-buttons {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.bibleversefind-submit-button,
.bibleversefind-reset-button {
    padding: 10px 20px !important;
    width: 50%;
    border-radius: 4px;
    border: none;
    background-color: #ff0000;
    color: #000000;
    cursor: pointer;
    font-size: 14px !important;
    transition: background-color 0.3s ease;
    text-align: center;
}

.bibleversefind-submit-button:hover,
.bibleversefind-reset-button:hover {
    background-color: #335b63;
}

.bibleversefind-highlight {
    color: red;
    font-weight: bold;
}

.bibleversefind-language-checkboxes {
    display: flex;
}

.bibleversefind-language-checkboxes label {
    display: flex;
    align-items: center;
}

.bibleversefind-language-checkboxes input {
    margin-left:-10px;
}

.verse-block {
    margin-bottom: 15px; 
}

.verse-line {
    padding-left: 15px;
    line-height: 1;
    font-size: 16px;
    color: #000000; 
}

.verse-block p:first-child {
    padding-left: 0; 
}

.verse-block p:not(:first-child) {
    padding-left: 15px; 
}

.verse-number {
    font-weight: 200;
    
}
.verse-row {
    display: flex;
    align-items: flex-start;
    
}

.verse-number-column {
    width: 2%; 
    font-weight: 500;
    text-align: right;
}

.verse-text-column {
    width: 95%; 
}

@media (max-width: 767px) {
    .bibleversefind-container {
        flex-direction: column;
    }

    .bibleversefind-form-container,
    .bibleversefind-result-container {
        width: 100%;
        margin-bottom: 20px;
    }
}
/* Loading Indicator Styles */
.loading-indicator {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    color: #000;
}

.loading-indicator:before {
    content: '';
    width: 40px;
    height: 40px;
    border: 5px solid #ccc;
    border-top-color: #007bff;
    border-radius: 50%;
    animation: spin 1s linear infinite;
    margin-right: 10px;
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}
