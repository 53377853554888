.song-details-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start; 
  background-color: #1e1e1e;
  color: #fff;
  height: 100vh;
  padding: 20px;
  box-sizing: border-box;
  overflow: hidden; 
  position: fixed;
  width: 100%;
  margin-top: 90px;
}

.song-details {
  width: 100%;
  max-width: 1200px;
  background-color: #2e2e2e;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  display: flex;
  flex-direction: column;
  justify-content: flex-start; 
}

.song-details h2.highlight {
  font-size: 24px;
  margin-bottom: 10px;
  color: #ffd000;
  word-break: break-word;
}

.text-container {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  width: 100%;
}

.text-left, .text-right {
  width: 48%;
  overflow: hidden; /* Ensure no overflow */
}

.text-left pre, .text-right pre {
  font-size: 18px;
  white-space: pre-wrap;
  margin: 0;
  font-family: inherit; 
}

.text-left pre {
  color: #ccc;
}

.text-right pre {
  color: #aaa;
}

.divider {
  width: 2px;
  background-color: #ffd000;
  margin: 0 10px;
}

.loading, .error {
  font-size: 18px;
  color: #ccc;
}
