.old-testament-header {
  display: flex;
  flex-direction: column; 
  background-color: #424b52df;
  padding: 10px 10px;
  position: fixed;
  top: 100px; 
  width: 99%;
  z-index: 1000;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.chapter-title {
  font-size: 1.5rem;
  font-weight: bold;
  color: #ffcc00;
  text-align: center; 
}

.header-second-line {
  display: flex;
  justify-content: space-between; 
}

.language-selection {
  display: flex;
  gap: 10px;
}

.language-selection label {
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 1rem;
  cursor: pointer;
  color: #ffcc00;
  transition: color 0.3s ease;
}

.language-selection input[type="checkbox"] {
  width: 20px;
  height: 20px;
}

.language-selection label.selected {
  color: #fff;
}

.navigation-buttons {
  display: flex;
  gap: 10px;
}

.navigation-buttons button {
  padding: 5px 10px;
  font-size: 0.9rem;
  background-color: #424b52;
  border: 0.1px solid #ffcc00;
  color: #fff;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.4s ease, transform 0.3s ease;
  font-weight: bold;
}

.navigation-buttons button:hover {
  background-color: #ffcc00;
  color: #333;
  transform: translateY(-5px);
}

/* Verses Section */
.old-testament-verses-container {
  background: linear-gradient(135deg, #ffffff, #f0f0f0); 
  flex-grow: 1;
  width: 90%;
}

.old-testament-verses-scroll-container {
  overflow-y: auto;
  height: calc(100vh - 150px); 
  background: linear-gradient(135deg, #ffffff, #f0f0f0); 
  margin-top: 110px;
}

/* Verse Container Styles */
.old-testament-verse-container {
  background-color: #ffffff;
  border-radius: 8px;
  margin-left: 10px;
  padding: 2px;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s ease;
}

.old-testament-verse-container:hover {
  background-color: #f9f9f9; 
}

.old-testament-verse-header {
  color: #2b2b2b;
  font-size: 1rem;
  margin-bottom: 5px; /* Reduced space under verse number */
}

.verse-content {
  font-size: 1rem;
  color: #333;
  line-height: 1;
}

/* Scrollbar customization */
.old-testament-verses-scroll-container::-webkit-scrollbar {
  width: 12px;
}

.old-testament-verses-scroll-container::-webkit-scrollbar-thumb {
  background-color: #ffcc00;
  border-radius: 6px;
}

.old-testament-verses-scroll-container::-webkit-scrollbar-track {
  background-color: #f0f0f0;
}

/* Desktop Styles */
@media (min-width: 601px) {
  .header-second-line {
    display: flex;
    justify-content: space-between; 
    align-items: center;
  }

  .language-selection {
    justify-content: flex-start; 
  }

  .navigation-buttons {
    justify-content: flex-end; 
  }

  /* Larger padding for desktop view */
  .old-testament-verses-scroll-container {
    padding-top: 60px;
  }
}

/* Mobile Styles */
@media (max-width: 600px) {
  .chapter-title {
    font-size: 1.4rem;
    text-align: center;
    margin-bottom: 10px;
  }

  /* Language selection and buttons should be stacked vertically in mobile view */
  .header-second-line {
    display: flex;
    flex-direction: column; 
    align-items: center; 
  }

  .language-selection {
    display: flex;
    justify-content: center; 
    gap: 5px;
    margin-bottom: 10px;
  }

  .navigation-buttons {
    display: flex;
    justify-content: center; 
    gap: 10px;
    margin-top: 10px;
  }

  .navigation-buttons button {
    padding: 8px 12px;
    font-size: 0.9rem;
  }

  .old-testament-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
  }

  /* Smaller padding for mobile view */
  .old-testament-verses-scroll-container {
    padding-top: 120px;
    height: calc(100vh - 180px); /* Adjust scroll height */
  }
}

.spinner-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 90vh;
}

.loading-spinner {
  border: 8px solid #f3f3f3;
  border-top: 8px solid #ffcc00;
  border-radius: 50%;
  width: 80px;
  height: 80px;
  animation: spin 1.5s linear infinite;
}
 
@keyframes spin {
  0% {
      transform: rotate(0deg);
  }
  100% {
      transform: rotate(360deg);
  }
}

.spinner-text {
  color: #ffffff;
  font-size: 1.5rem;
  margin-top: 10px; 
  font-weight: bold;
}
