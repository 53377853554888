/* PrivacyPolicy.css */
.privacy-policy-container {
    /* max-width: 1200px; */
    margin: 0 auto;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); 
    margin-top: 80px;
  }
  
  .privacy-policy-container h2 {
    text-align: center;
    font-size: 2rem;
    color: #333;
  }
  
  .privacy-policy-container p {
    font-size: 1.2rem;
    color: #555;
    line-height: 1.5;
  }
  
  .privacy-policy-container h3 {
    margin-top: 20px;
    font-size: 1.5rem;
    color: #333;
  }
  