body, html {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* General styles for top header */
.top-header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #424b52;
  color: rgb(205, 205, 205);
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 1000; 
  height: 60px; 
}

/* Logo and Menu container styles */
.logo-menu-container {
  display: flex;
  align-items: center;
  justify-content: start;
  width: 100%;
  padding: 10px 10px; 
}

.logo-container {
  max-width: 120px;
}

.logo {
  width: 100%;
}

.menu-toggle {
  display: none;
  font-size: 24px;
  color: white;
  cursor: pointer;
}


.menu {
  display: flex;
  align-items: center;
}

.menu-item {
  display: flex;
  align-items: center;
  color: #ffffff;
  font-size: 14px;
  text-decoration: none;
  transition: color 0.3s ease-in-out, background-color 0.3s ease-in-out;
  font-family: Arial, sans-serif;
  padding: 1px;
  text-transform: capitalize;
}

.menu-item:hover {
  color: #ffcc00;
}

/* Social icons */
.social-icons {
  display: flex;
  align-items: center;
}

.social-icon {
  color: #ffffff;
  margin-left: 10px;
  font-size: 18px;
  text-decoration: none;
}

.social-icon:hover {
  color: #ffcc00;
}

/* Privacy policy and Support links */
.privacy-policy-link,
.support-link {
  color: rgb(255, 255, 255);
  font-size: 14px;
  margin-left: 10px;
  text-decoration: none;
}

.privacy-policy-link:hover,
.support-link:hover {
  color: #ffcc00;
}

/* Language selector */
.language-selector {
  display: flex;
  align-items: center;
}

.language-selector select {
  padding: 5px;
  font-size: 14px;
  background-color: #424b52;
  color: white;
  border: 1px solid white;
  border-radius: 5px;
  cursor: pointer;
}

.language-selector select:focus {
  outline: none;
  border-color: #ffcc00;
}

/* Contact Info */
.contact-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 6px 10px;
  background-color: #2d343a;
  color: rgb(205, 205, 205);
  position: fixed;
  top: 60px;
  width: 99%;
  z-index: 999;
}

/* Mobile & Tablet styles (max-width: 1024px) */
@media (max-width: 1024px) {
  .logo-menu-container {
    padding: 0 10px; 
    justify-content: space-between; 
  }

  /* Display the menu toggle button */
  .menu-toggle {
    display: block;
  }

  /* Hide the regular menu in mobile view */
  .menu {
    display: none;
  }

  /* Show menu when the toggle is clicked */
  .menu.open {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 60px;
    left: 0;
    width: 100%;
    background-color: #424b52;
    padding: 10px 0;
  }

  /* Styling for menu items in mobile view */
  .menu-item {
    padding: 15px;
    width: 100%;
    font-size: 16px;
    border-bottom: 1px solid #909090;
  }

  .menu-item:last-child {
    border-bottom: none;
  }

  /* Social icons in mobile view */
  .social-icons {
    display: flex;
    justify-content: flex-end;
    padding-right: 20px;
  }

  /* Adjust language selector in mobile */
  .language-selector {
    margin-right: 15px;
  }
}

/* Tablet/Desktop view (min-width: 1025px) */
@media (min-width: 1025px) {
  .menu-toggle {
    display: none;
  }

  /* Show menu on desktop */
  .menu {
    display: flex;
  }

  .menu-item {
    font-size: 14px;
    padding: 10px 20px;
  }

  .menu-item:hover {
    color: #ffcc00;
  }

  .contact-info {
    display: flex;
    flex-direction: row;
  }
}
