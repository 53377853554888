.subcategories-container {
    padding: 30px;
    background-color: #ffffff;
    border-radius: 12px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    position: fixed; 
    top: 90px; 
    left: 0;
    right: 0; 
    height: calc(100vh - 90px);
    overflow-y: auto; 
    max-width: 100%; 
    margin-left: auto;
    margin-right: auto;
    transition: all 0.3s ease-in-out;
}

.subcategories-container h2 {
    font-size: 25px;
    font-weight: 700;
    color: #2c3e50;
}

.subcategories-container ul {
    list-style-type: none;
    padding: 0;
}

.subcategories-container ul li {
    padding: 15px;
    background-color: #f8f8f8;
    border-radius: 10px;
    cursor: pointer;
    transition: background-color 0.3s ease, box-shadow 0.3s ease;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
}

.subcategories-container ul li:hover {
    background-color: #ececec;
    box-shadow: 0 6px 16px rgba(0, 0, 0, 0.15);
}

.subcategories-container ul li {
    font-size: 18px;
    font-weight: 500;
    color: #34495e;
}

.error {
    color: #e74c3c;
    text-align: center;
    font-weight: 500;
    margin-top: 20px;
}

.loading-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 90vh;
    flex-direction: column;
}

.loading-spinner {
    border: 8px solid #f3f3f3;
    border-top: 8px solid #ffcc00;
    border-radius: 50%;
    width: 80px;
    height: 80px;
    animation: spin 1.5s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.loading-container p {
    font-size: 18px;
    color: #34495e;
    margin-top: 15px;
    font-weight: 600;
    letter-spacing: 0.5px;
}

/* Responsive Styles */
@media (max-width: 1024px) {
    .subcategories-container {
        padding: 20px;
        max-width: 95%;
    }

    .subcategories-container h2 {
        font-size: 24px;
    }

    .subcategories-container ul li {
        font-size: 17px;
    }
}

@media (max-width: 768px) {
    .subcategories-container {
        padding: 15px;
        max-width: 95%;
    }

    .subcategories-container h2 {
        font-size: 22px;
    }

    .subcategories-container ul li {
        font-size: 16px;
    }
}

@media (max-width: 480px) {
    .subcategories-container {
        padding: 10px;
        max-width: 98%;
    }

    .subcategories-container h2 {
        font-size: 20px;
    }

    .subcategories-container ul li {
        font-size: 15px;
    }
}
